@import "_base.scss";
/* text colors, fonts, etc */
p {
  line-height: 1.5;
  margin-bottom: 1em;
}

.item-list ul li.views-row {
  padding-bottom: 0.5em;
}

a,
a:link {
  text-decoration: underline;
  color: #ed1c24;
}

a:visited {
  color: #a40610;
}

a:hover {
  text-decoration: none;
}

#RRcredit {
  font-family: verdana, sans-serif;
  text-align: left;
  line-height: 1.2;
}

#RRcredit a {
  color: #0071bc;
}

#RRcredit .subtext {
  font-size: 85%;
}

#RRcredit .subtext a {
  color: #ed1c24;
}

#block-block-2 {
  display: inline;
  float: right;
} /* weta logo */

#block-menu-menu-mini a {
  color: #ed1c24;
}

#slogans h6,
#slogans p {
  margin: 0;
  padding: 0;
  font-family: verdana, sans-serif;
  color: #fff;
}

#slogans h6 {
  font-size: 20px;
  font-weight: normal;
} /* FONT SIZE FIX */

ul#main-menu li a,
ul#superfish-1 li a {
  color: #fff;
}

h1,
#caroIntro,
h2,
h3,
.blue {
  color: #0071bc;
} /* blue color */

p#caroIntro {
  font-size: 15px;
  padding-left: 1em;
}

.red {
  color: #ed1c24;
} /* red color */

h1,
h2 {
  margin-bottom: 0;
  clear: both;
}

h2 + h3 {
  margin-top: 0.5em;
}

#three33-content-wrapper h2.block-title {
  font-weight: normal;
  font-size: 1.7em;
  font-family: verdana, sans-serif;
}

#three33-content-wrapper ul {
  margin: 0 0 1em 1em;
  list-style-image: url("../images/red_bullet.gif");
}

.region-three-33-first .block-inner,
.region-three-33-second .block-inner {
  border-right: dotted 1px #0071bc;
  padding-right: 1em;
}

#project {
  font-size: 1em;
}

.intro {
  color: #0071bc;
}

#tertiary-content-wrapper ul.menu li a {
  color: #fff;
}

.mouse {
  color: #8a8a8a;
  font-size: 85%;
}

.region-three-33-third .view-footer p {
  margin: 0.5em 0;
}

#block-views-reading-tips-block-2.block {
  margin-bottom: 0;
}

.article-content ul li {
  margin-bottom: 0.75em;
}

/* layout backgrounds etc. */
#header-wrapper {
  background-color: none !important;
}

.front #main-content,
.page-node-1907 #main-content {
  /*background-color: #fff9e1; */
  padding-bottom: 2em;
  margin-right: 0;
}

.no-sidebars #main-content {
  margin-right: 0;
}
/*body.not-front #content {background-color: #fff;}*/

.front h1#page-title,
.page-node-1907 h1#page-title {
  font-family: multi-display, sans-serif;
  font-weight: normal;
  font-size: 1.7em;
  margin: 0 0 0.3em 10px;
  padding-top: 0.5em;
  padding-left: 15px;
  color: #ed1c24;
}
/*.front #main-content header {background-color: #fff9e1;}*/

/* OVERALL LAYOUT, MARGINS, WIDTH ETC. */
#content-wrapper,
#header-wrapper .container,
#nav-wrapper .container,
.container,
#footer-wrapper .container,
#three33-content-wrapper .container,
#tertiary-content-wrapper .container,
#two50-content-wrapper .container,
#bottom-banner-content-wrapper {
  margin: 0 auto;
}

#secondary-content-wrapper {
  z-index: -999;
  position: relative;
}

.page-node-1907 #secondary-content-wrapper,
.front #secondary-content-wrapper {
  z-index: auto; /*position: static;*/
  overflow: visible;
}

#secondary-content-wrapper .block-inner/*, .front #secondary-content-wrapper .block, .front #secondary-content-wrapper p*/ {
  margin: 0;
  line-height: 1;
  font-size: 0;
}

.page-node-1907 #secondary-content-wrapper .block-inner,
.front #secondary-content-wrapper .block-inner {
  font-size: 1em;
}

.region-footer {
  line-height: 1.2;
}

#block-block-18 .block-inner,
#block-block-19 .block-inner,
#block-block-22 .block-inner,
#block-block-24 .block-inner,
#block-block-25 .block-inner,
#block-block-27 .block-inner,
#block-block-28 .block-inner {
  margin: 0;
}

#block-block-18 {
  margin-bottom: 10px;
}

#block-block-22 {
  border: solid 1px #056fbd; /*box-shadow: 1px 1px 7px #737373;*/
  background-color: #056fbd;
}

#block-block-22 p,
#block-block-22 h5 a {
  color: #fff;
}

#block-block-22 p,
#block-block-22 h5 {
  margin-left: 1em;
  margin-right: 1em;
}

#block-block-18 p {
  margin: 0;
}

#project a {
  color: #fff;
}

#two50-content-wrapper p {
  margin-bottom: 1em;
}

#block-views-reading-tips-block-2 .views-row {
  line-height: 1.3;
}

#article-1370.article {
  margin-bottom: 10px;
}
/* OTHER */

.view-id-booklist .view-content h3 {
  margin: 25px 0 0.5em 0;
}

.view-id-booklist .view-footer .view-content .item-list:first-of-type h3,
.view-id-booklist .view-footer .view-content .item-list:nth-child(2) h3 {
  margin-top: 0;
}

.view-related-videos .item-list ul {
  margin: 0;
  list-style: none;
}

.view-related-videos .item-list li {
  list-style: none;
}

#block-views-related-block .block-inner,
#block-views-related-videos-block-2 .block-inner,
#block-views-related-videos-block-1 .block-inner {
  padding: 0;
  background-color: #fff9e1;
  margin: 0;
}

#block-views-related-block,
#block-views-related-videos-block-2,
#block-views-related-videos-block-1 {
  .view-content {
    padding: 0 15px 10px;
  }
}

#block-views-related-block h3,
#block-views-related-videos-block-2 h3 {
  font-size: 1.231em;
  font-family: multi-display, sans-serif;
  margin-top: 1em;
}

#block-views-related-block h3 .taxDescrip,
#block-views-related-videos-block-2 h3 .taxDescrip {
  color: #000;
  font-size: 1.231em;
}

#block-views-related-block h3 .taxDescrip p,
#block-views-related-videos-block-2 h3 .taxDescrip p {
  margin-bottom: 0.8em;
  padding-top: 10px;
}

#block-views-related-block h2.block-title,
#block-views-related-videos-block-2 h2.block-title,
#block-views-related-videos-block-1 h2.block-title {
  color: #fff;
  font-size: 1.7em;
  font-weight: normal;
  margin: 0.2em 0 1.5em 0;
}

#block-views-related-block h3,
#block-views-related-videos-block-2 h3,
#block-views-related-videos-block-1 h3 {
  margin: 0 0 0.5em;
  vertical-align: top;
}

#block-views-related-block h2.block-title {
  font-size: 1.7em;
}

#block-views-dc-for-kids-block-1 .view-dc-for-kids h3 {
  border-top: none;
  padding-top: 0;
}

#block-views-dc-for-kids-block-1 .views-field-body,
#block-views-dc-for-kids-block-1 div.related {
  font-size: 80%;
  line-height: 1.1;
}

#block-views-dc-for-kids-block-1 div.related {
  margin-top: 1em;
}

#block-views-dc-for-kids-block-1 .views-field-field-url {
  line-height: 1.3;
  margin-bottom: 0.3em;
}

.view-dc-for-kids {
  .view-header {
    clear: both;

    .credit {
      font-size: 80%;
      color: #ccc;
    }

    .left {
      float: left;
      margin: 0 1em 1em 0;
      overflow: hidden;
    }

    .intro {
      float: left;
    }
  }

  .views-row {
    margin-bottom: 1em;
  }

  .street-block,
  .locality-block {
    display: inline;

    * {
      display: inline;
    }
  }

  .views-field-field-nearest-metro-stations {
    display: block;
    margin: 0 0 1em 0;
  }
}

/*.view-id-related_videos .item-list ul h3 {margin-top: 2em;}*/
.view-id-related ul li.views-row {
  padding-bottom: 0.3em;
}

#block-views-related-dc-activities-block,
#block-views-dc-for-kids-block-1 {
  border: solid 1px #0071bc;
  padding: 0 0 6em 0;
  background: #fff url("../images/dc_bottom.png") no-repeat center bottom;
}

#block-views-dc-for-kids-block-1 {
  padding-bottom: 10em;
}

#block-views-dc-for-kids-block-1 .view-dc-for-kids {
  padding: 1em;
}

#block-views-related-dc-activities-block .block-inner,
#block-views-dc-for-kids-block-1 .block-inner {
  margin: 0;
}

#block-views-related-dc-activities-block h2.block-title,
#block-views-dc-for-kids-block-1 h2.block-title {
  background-color: #0071bc;
  color: #fff;
  padding: 0.5em 1em 0.6em 1em;
  line-height: 1.3;
}

#block-views-related-dc-activities-block h3/*, #block-views-dc-for-kids-block-1 h3 */ {
  /*color: #000;*/
  font-size: 140%;
  margin: 1em 0 0.5em 0; /*font-weight: bold;*/
}

#block-views-related-dc-activities-block li.views-row,
#block-views-dc-for-kids-block-1 li.views-row {
  margin-bottom: 0.5em;
  line-height: 1.1;
}

#block-views-related-dc-activities-block li.views-row span.descrip/*, #block-views-dc-for-kids-block-1 .views-row p.descrip */ {
  font-size: 85%;
  line-height: 1.3;
}

.view-dc-for-kids .views-row .descrip {
  margin-bottom: 0.5em;
}

#moredc {
  margin-top: 2em;
}

.view-id-dc_for_kids .views-row p {
  margin-bottom: 0.7em;
}

.sidebar h2.block-title {
  font-size: 160%;
  font-weight: normal;
}

#block-views-reading-tips-block,
#block-views-reading-tips-block-1 {
  /*border: solid 1px #8761af; background-color: #b2dbec;*/
  background-color: #d1ecf7;
}

#block-views-reading-tips-block .block-inner,
#block-views-reading-tips-block-1 .block-inner {
  margin: 0;
}

#block-views-reading-tips-block h2.block-title,
#block-views-reading-tips-block-1 h2.block-title {
  background-color: /*#8761af*/ #0071bc;
  color: #fff;
  padding: 0.5em 1em;
}

#block-views-reading-tips-block .view-reading-tips,
#block-views-reading-tips-block-1 .view-reading-tips {
  padding: 1em;
  line-height: 1.3em;
}

#block-views-reading-tips-block .view-footer,
#block-views-reading-tips-block-1 .view-footer {
  margin-top: 1em;
  color: #0071bc;
}

#block-views-reading-tips-block .view-footer #phone,
#block-views-reading-tips-block-1 .view-footer #phone {
  /*vertical-align: middle;*/
  margin: 1em 0 0 0;
}

.front #phone {
  margin-top: 1em;
  color: #0071bc;
}

.front #phone img {
  float: left;
  margin: 0.5em 1em 0 0;
}

ul#main-menu li,
ul#superfish-1 li {
  font-size: 13px;
}

ul#superfish-1 li#menu-791-1,
ul#superfish-1 li#menu-698-1,
ul#superfish-1 li.sf-depth-2 {
  border-right: none;
}

#RRcredit {
  float: left;
  position: relative;
}

#RRcredit .credit {
  float: left;
}

#RRcredit .graphic {
  float: left;
}

.jcarousel-skin-default .jcarousel-next,
.jcarousel-skin-default .jcarousel-prev {
  background-image: url("../images/sprite_red.png");
}

/*img.left {float: left;}*/

#weta,
#block-menu-menu-mini {
  margin: 1em 0;
}

#block-menu-menu-mini ul.menu {
  margin: 0;
  font-size: 85%;
}

#block-menu-menu-mini ul.menu li {
  line-height: 1;
  padding-top: 0;
}
.region-header .block {
  margin-bottom: 0;
}

/*#block-block-13 {padding-top: .8em;}*/

#bottom-banner-content-wrapper {
  background: #fff url("../images/zig.png") repeat-x center top;
}

#bottom-banner-content-wrapper .container {
  padding: 1em;
}

.front #bottom-banner-content-wrapper,
.page-node-1907 #bottom-banner-content-wrapper {
  background-color: #fff9e1;
}

#bottom-banner-content-wrapper h4#moreRR {
  color: #fff;
  font-weight: normal;
  font-size: 17px;
  margin-top: -5px;
  font-family: $body-font;
}

.three-3x33 {
  background-color: #fff9e1;
  border-bottom: solid 1px #0071bc;
  margin-bottom: 1em;
}

.two-50 .region .block-inner {
  margin: 0;
}

.two-50 .region-two-50-first .block-inner {
  border-right: dotted 1px #0071bc;
  padding-right: 1em;
  margin-right: 1em;
}

#tertiary-content-wrapper ul.menu {
  margin: 0;
}

#tertiary-content-wrapper ul.menu li {
  list-style-image: none;
  list-style-type: none;
  display: inline;
  border-right: solid 1px #fff;
  padding-right: 1em;
  margin-right: 1em;
}

#tertiary-content-wrapper ul.menu li.last {
  border-right: none;
}

#tertiary-content-wrapper {
  background-color: #2294d3;
  padding: 0.5em 0;
}

#tertiary-content-wrapper .block {
  margin-bottom: 0;
}

#footer-wrapper {
  background-color: #0069af;
  color: #fff;
}

#footer-wrapper a {
  color: #fff;
}

#footer-wrapper ul {
  margin: 0;
}

/* social media buttons in footer */
#block-block-12 ul {
  list-style: none;
}

#block-block-12 ul li {
  display: inline-block;
  vertical-align: middle;
}

ul#superfish-1 li ul {
  background-color: #2294d3;
}

.kicker {
  color: #ed1c24;
  font-size: 110%;
}

.kicker + h1.title {
  margin-top: -0.2em;
}

h1.title,
h1#page-title {
  font-family: multi-display, sans-serif;
  font-size: 2.4rem;
  margin-bottom: 0.4em;
  margin-top: 0;
}

.contentbox {
  border-top: solid 1px #ccc;
}

h2.contentbox {
  border-top: solid 1px #ccc;
  padding-top: 0.5em;
  margin-top: $horiz-gutter*3;
}

#article-1474 .contentbox h3 {
  margin: 1em 0 0.5em 0;
}

hr.contentbox {
  border: none;
  border-top: solid 1px #ccc;
}

.contentbox,
.contentbox .row {
  clear: both;
}

.item-list li {
  list-style: disc;
}

.view-booklist .views-row {
  clear: both;
}

.view-booklist .views-row-last + h3 {
  border-top: solid 1px #ccc;
  padding-top: 1em;
}

.bookbox {
  clear: both;
  margin-bottom: 1em;
  overflow: hidden;
}

/*.book_blurb p {margin-bottom: .8em;}*/
.book_blurb p.book_title {
  margin-bottom: 0;
  color: #0071bc;
  line-height: 1.35;
}

.book_blurb p.bookdata {
  color: $swab-black;
  font-size: 80%;
  margin-bottom: 0;
}

.bookdescrip {
  margin: 0.5em 0 1em 0;
}

.bookdescrip,
.bookdescrip p {
  font-size: 11px;
} /* including both to account for html/non-html descriptions */

.book_blurb p.purchasebook {
  font-size: 85%;
  margin-bottom: 0.2em;
}

#block-menu-menu-mini ul.menu li {
  display: inline;
  vertical-align: middle;
  border-right: solid 1px #000;
  padding-right: 1em;
  margin-right: 0.7em;
}

#block-menu-menu-mini ul.menu li.last {
  border-right: none;
}

#superfish-1 a:hover {
  text-decoration: underline;
}

ul#superfish-1 li {
  padding: 1em 0.9em;
}

ul#superfish-1 li ul {
  padding: 1em 0.5em !important;
}

ul#superfish-1 li ul li {
  padding: 0.3em 0;
  font-size: 11px;
  margin-bottom: 0.5em !important;
}

ul.sf-menu li ul li a {
  height: 1.2em;
  line-height: 1.2em;
}

.jcarousel-skin-default .jcarousel-item {
  border: solid 1px #fff;
}

.jcarousel-skin-default .jcarousel-container-horizontal,
.jcarousel-skin-default .jcarousel-clip-horizontal {
  padding: 0;
}

.caroHov span {
  position: relative;
  top: -2.7em;
  background-color: #0071bc;
  display: block;
  padding: 0.5em 0;
}

.caroHov.c2 span,
.caroHov.c6 span,
.caroHov.c10 span,
.caroHov.c14 span,
.caroHov.c18 span,
.caroHov.c22 span,
.caroHov.c26 span,
.caroHov.c30 span {
  background-color: #39b54a;
} /* green */
.caroHov.c3 span,
.caroHov.c7 span,
.caroHov.c11 span,
.caroHov.c15 span,
.caroHov.c19 span,
.caroHov.c23 span,
.caroHov.c27 span,
.caroHov.c31 span {
  background-color: #8761af;
} /* purple */
.caroHov.c4 span,
.caroHov.c8 span,
.caroHov.c12 span,
.caroHov.c16 span,
.caroHov.c20 span,
.caroHov.c24 span,
.caroHov.c28 span,
.caroHov.c32 span {
  background-color: #f15a24;
} /* orange */
a.caroHov {
  color: #fff;
  font-family: $body-font;
}

.contentbox .row .box {
  float: left;
  margin: 0 2em 2em 0;
}

.contentbox .row .box a span {
  position: relative;
  top: -5px;
  background-color: #0071bc;
  color: #fff;
  padding: 0.5em 0;
  width: 100%;
  text-align: center;
  display: block;
}

.contentbox .row .box.exception a span {
  font-size: 85%;
  padding: 0.75em 0;
}

.contentbox .row .box a span.green {
  background-color: #39b54a;
}

.contentbox .row .box a span.purple {
  background-color: #8761af;
}

.contentbox .row .box a span.orange {
  background-color: #f15a24;
}

.left img,
img.left,
embed.left {
  float: left;
  margin: 0 1em 0.75em 0;
}

.fRight {
  float: left;
  width: auto;
}

div.view-header > h2 > img {
  vertical-align: sub;
}

ol.ccn {
  margin: 0;
  font-size: 105%;
}

ol.ccn li {
  list-style: none;
  background-repeat: no-repeat;
  padding: 0 0 1.2em 2.5em;
}

ol.ccn li.one {
  background: url("../images/circle_1.png") no-repeat;
}

ol.ccn li.two {
  background-image: url("../images/circle_2.png");
}

ol.ccn li.three {
  background-image: url("../images/circle_3.png");
}

ol.ccn li.four {
  background-image: url("../images/circle_4.png");
}

ol.ccn li.five {
  background-image: url("../images/circle_5.png");
}

.view-display-id-attachment_1 .left img {
  margin: 0 1em 1em 0;
}

.view-booklist .view-header {
  clear: both;
}

.view-id-flb_featured_graphic {
  float: left;
}

.view-id-related_literacy_bags .left {
  float: left;
  margin-right: 1em;
}

.view-id-related_family_literacy_bags .view-content .item-list,
.view-id-related_literacy_bags .view-content {
  float: left;
}

.view-id-related_literacy_bags .view-content .item-list ul {
  li.views-row {
    padding-bottom: 0.5em;

    a:first-child {
      display: inline-block;
      padding-bottom: 4px;
    }
  }
}

.view-id-related_family_literacy_bags .view-footer,
.view-id-related_literacy_bags .view-footer {
  clear: both;
  float: right;
  padding-right: 3em;
  position: relative;
  top: -1em;
}

.view-booklist .views-row .book_cover {
  margin: 0 1em 1em 0;
}

#main-content .view-dc-for-kids h2 {
  border-top: solid 1px #ccc;
  padding-top: 1em;
  margin: 1em 0 0.5em 0;
}

.view-dc-for-kids .dcrelated {
  font-size: 85%;
  line-height: 1.3;
}

#block-block-9 h3 {
  line-height: 1.2;
}

.article-type-page .field-name-field-image {
  margin: 0 $horiz-gutter*1.5 $horiz-gutter*1.5 0;
  float: left;
}
.article-type-page .field-name-field-intro {
  float: left;
  color: #0071bc;
  line-height: 1.3;
}
.article-type-page .field-name-body {
  clear: both;
}
#article-1474.article-type-page .field-name-body {
  clear: both;
  border-top: none;
  padding-top: 0;
}
/*.front .article-type-page .field-name-body*/ /*, .page-node-1907 .article-type-page .field-name-body*/ /*{clear: both; border-top: none; margin-top: 0; padding-top: 0;}*/

ul#share {
  /*overflow: auto;*/
  margin: 0;
  padding: 0;
  width: 100%;
}
ul#share li {
  list-style: none;
  display: inline;
  float: left;
}
ul#share .fb-like {
  /*position: relative; top: -2px;*/
}
ul#share .twitter-share-button {
  width: 80px !important;
}
ul#share #___plusone_0 {
  width: 60px !important;
}

/*#block-block-21 {display: inline; float: right; width: 120px;}*/
/*#block-block-21 + #block-system-main .block-inner.clearfix, #block-block-21 + #block-system-main .block-inner.clearfix:after {clear: none;}*/
ul#shareV {
  margin: 0;
  padding: 0;
}
ul#shareV li {
  list-style: none;
  margin-bottom: 5px;
}
div#block-print-print-links span {
  display: inline; /* height: 24px;*/
}
#block-print-print-links span img {
  vertical-align: top;
}
#block-print-print-links.block,
#block-block-20.block {
  margin-bottom: 10px;
}
#block-block-20 {
  /*width: 320px;*/
  float: left;
}
#block-print-print-links {
  display: inline; /*width: 70px;*/
  float: left; /* vertical-align: top;*/
}
.print_html,
.print_mail,
.print_pdf {
  margin: 0 10px 0 0;
}
.print_mail img {
  margin-top: 1px;
}
#block-system-main {
  clear: both;
}
#block-views-related-block h3 .taxDescrip p {
  font-size: 13.1px;
}

.views-field-field-spanish-title {
  margin-top: 1em;
}

.not-front #bottom-banner-content-wrapper {
  clear: both;
  margin-top: 1em;
}

.olLayerGooglePoweredBy.olLayerGoogleV3.gmnoprint {
  visibility: hidden;
}

#article-1369 h2 {
  margin-bottom: 0.5em;
}

#main-content > header {
  margin: 0;
}

#cse table,
#cse td,
#cse tr,
#cse tbody,
#cse-search-form table,
#cse-search-form table,
#cse-search-form td,
#cse-search-form tr,
#cse-search-form tbody {
  border-collapse: separate;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}
ul#main-menu li,
ul#superfish-1 li {
  font-size: 12px;
}
.cse .gsc-search-button input.gsc-search-button-v2,
input.gsc-search-button-v2 {
  padding: 7px 10px !important;
  position: relative;
  top: 1px;
}
.cse input.gsc-search-button,
input.gsc-search-button {
  background-color: #ffaf43 !important;
  background-image: none !important;
  border: 1px solid #1172a9 !important;
  color: #000 !important;
}
table.gsc-search-box,
table#gs_id0 {
  border-collapse: separate;
}
.gsc-search-box-tools .gsc-search-box .gsc-input {
  padding-right: 0 !important;
}
table#gs_id0 {
  /*height: 10px*/ /*margin: 0 !important;*/
}
input.gsc-input {
  /*padding: 0 !important;*/
}
form.gsc-search-box {
  margin: 0 !important;
}
table.gsc-search-box {
  margin-bottom: 0 !important;
}
nav#block-superfish-1 {
  display: inline;
  float: left;
  padding-top: 2px;
  margin-right: 0;
}
td.gsc-search-button {
  padding: 0;
}

#block-views-related-videos-block-1 li.views-row .views-field-field-image,
#block-views-related-block li.views-row .views-field-field-image {
  padding-bottom: 1em;
  margin-bottom: 1em;
  border-bottom: solid 1px #ccc;
}

.blog-landing-intro img {
  float: left;
  width: 30%;
}

.blog-landing-intro-text {
  float: left;
  margin-left: 20px;
  width: 60%;
}

.view-blog-landing-page .views-row {
  border-top: solid 1px #ccc;
  padding-top: 10px;
}

.views-slideshow-pager-field-item {
  /*height: 81px; */
  background-color: #0e76bc;
  cursor: pointer;
}

#views_slideshow_pager_field_item_top_slideshow-block_2,
#views_slideshow_pager_field_item_top_slideshow-block_1_1 {
  height: 83px;
}

.views-slideshow-pager-field-item:hover,
.views-slideshow-pager-field-item.active {
  background-color: #298aca;
}

#block-views-slideshow-block .views-field-title,
#block-views-slideshow-block-1 .views-field-title {
  padding: 1em;
  color: #fff;
  font-family: $body-font;
  line-height: 1.3;
  font-size: 1.2em;
}

#block-views-slideshow-block-1 .views-field-title {
  padding: 0.5em;
}

#block-views-slideshow-block .views-field-title a,
#block-views-slideshow-block-1 .views-field-title a {
  color: #fff;
}

.views-field-field-mini-image {
  width: 33px;
  float: left;
  padding: 1.5em;
}

.view-display-id-block_1 .views-field-field-mini-image {
  padding: 0.5em;
}
/*#views_slideshow_cycle_main_slideshow-block {max-width: 100% !important;}*/
/*#views_slideshow_cycle_main_slideshow-block img {max-width: none;}*/

.page-node-1907 #content-wrapper,
.front #content-wrapper {
  clear: both;
}

.views-slideshow-cycle-main-frame-row,
.views-slideshow-cycle-main-frame {
  /*width: 100% !important;*/
  height: 100% !important;
}

.views-slideshow-cycle-main-frame {
  display: block;
  position: relative !important;
}
#views_slideshow_pager_field_item_top_slideshow-block_0,
#views_slideshow_pager_field_item_top_slideshow-block_1_0 {
  background: #0e76bc url("../images/ss_inactive_bg.png") repeat-x 0 0;
}
#views_slideshow_pager_field_item_top_slideshow-block_0:hover,
#views_slideshow_pager_field_item_top_slideshow-block_0.active,
#views_slideshow_pager_field_item_top_slideshow-block_1_0:hover,
#views_slideshow_pager_field_item_top_slideshow-block_1_0.active {
  background: #298aca url("../images/ss_active_bg.png") repeat-x 0 0;
}
/*.views-slideshow-controls-top, #views_slideshow_cycle_main_slideshow-block {float: left;}*/
.front #block-views-slideshow-block {
  margin-bottom: 0;
}

/*#imageDataContainer #imageData {height: 0; display: none; padding: 0}*/
#imageData #bottomNav {
  height: 0;
}

.lightbox2-alt-layout #imageData #bottomNav,
.lightbox2-alt-layout-data #bottomNav {
  margin-bottom: 0;
}

.lightbox2-alt-layout-data #bottomNavClose,
.lightbox2-alt-layout #bottomNavClose {
  margin-top: 0;
}

.view-id-reading_tips.view-display-id-block_3 {
  line-height: 1.3;
}

ul.flex-direction-nav,
.flex-direction-nav {
  line-height: normal;
}

.see-all {
  padding-top: 20px;
  clear: both;
  position: relative;
  float: right;
}

/* Science Topic Styles */
.field.field-name-field-science-image.field-type-image.field-label-hidden {
  display: inline-block;
  width: 380px;
  float: left;
  margin-right: 30px;
  padding-bottom: 20px;
}

.field.field-name-field-science-intro.field-type-text-long.field-label-hidden {
  color: #0071bc;
}

.article-science-topic ul {
  margin-left: 20px;
  display: inline-block;
  font-size: 105%;
}

h2#activities,
h2#books,
h2#websites,
h2#apps,
h2#writing {
  font-size: 1.7em;
  margin-bottom: 20px;
  border-top: 1px solid lightgray;
  padding-top: 10px;
}

.view-display-id-activities > .view-content > .views-row {
  width: 30%;
  display: block;
  float: left;
  margin-right: 3%;
  margin-bottom: 15px;
}

.view-display-id-activities > .view-content > .views-row a:hover {
  text-decoration: underline;
  color: #0071bc;
}
@media screen and (max-width: 750px) {
  .view-display-id-activities > .view-content > .views-row {
    width: 44%;
    display: block;
    float: left;
    margin-right: 3%;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 480px) {
  .view-display-id-activities > .view-content > .views-row {
    width: 100%;
    max-width: 380px;
    display: block;
    float: left;
    margin-right: 0px;
    margin-bottom: 15px;
  }
}

.view-display-id-activities
  > .view-content
  > .views-row
  > .views-field-field-url
  > .field-content
  div {
  font-size: 1.231em;
  display: block;
  margin-bottom: 15px;
  color: #0071bc;
  height: 50px;
  margin-top: -5px;
  background-color: #e6e7e8;
}

.view-display-id-books {
  .view-content {
    .views-row {
      width: 21%;
      display: block;
      float: left;
      margin-right: 4%;
      padding-bottom: 20px;
      &:nth-child(4n + 1) {
        clear: both;
      }
      a {
        font-size: 1em;
        color: #0071bc;
        &:hover,
        &:focus {
          text-decoration: underline;
          color: #0071bc;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .view-display-id-books > .view-content > .views-row {
    width: 45%;
    max-width: 270px;
    display: block;
    float: left;
    margin-right: 3%;
    padding-bottom: 40px;

    &:nth-child(odd) {
      clear: both;
    }
  }
}

.view-display-id-books
  > .view-content
  > .views-row
  > .views-field-field-url
  > .field-content
  div {
  font-size: 1.231em;
  padding: 10px;
  display: block;
  padding-bottom: 15px;
  color: #0071bc;
  height: 50px;
  margin-top: -5px;
  background-color: #e6e7e8;
}

.view-display-id-books .views-field.views-field-title {
  display: block;
  background-color: transparent;
}

.view-display-id-websites > .view-content .views-row,
.view-display-id-apps > .view-content .views-row,
.view-display-id-writing > .view-content .views-row {
  width: 30%;
  float: left;
  margin-right: 3%;
  padding-bottom: 35px;
}

.view-display-id-websites > .view-content ul,
.view-display-id-apps > .view-content ul,
.view-display-id-writing > .view-content ul {
  width: 100%;
  font-size: 1.231em;
}

@media screen and (max-width: 750px) {
  .view-display-id-websites > .view-content .views-row,
  .view-display-id-apps > .view-content .views-row,
  .view-display-id-writing > .view-content .views-row {
    width: 44%;
    float: left;
    margin-right: 3%;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 480px) {
  .view-display-id-websites > .view-content .views-row,
  .view-display-id-apps > .view-content .views-row,
  .view-display-id-writing > .view-content .views-row {
    width: 100%;
    max-width: 380px;
    float: left;
    margin-right: 0px;
    margin-bottom: 15px;
  }
}

#block-views-science-topic-carousel-block div.block-inner {
  margin-right: -10px;
  margin-left: -10px;
}

#block-views-science-topic-carousel-block
  > div
  > div.block-content.content
  > div
  > div
  div.field-content {
  float: left;
  max-width: 30%;
  padding: 5px;
  font-size: 1.231em;
}

#block-views-science-topic-carousel-block
  > div
  > div.block-content.content
  > div
  > div
  div.field-content
  img {
  max-height: 100%;
}

#block-views-science-topic-carousel-block
  > div
  > div.block-content.content
  > div
  > div
  div.field-content
  span {
  text-align: center;
}
@media screen and (max-width: 480px) {
  #block-views-science-topic-carousel-block
    > div
    > div.block-content.content
    > div
    > div
    div.field-content {
    max-width: 380px;
    margin-left: 10px;
    margin-bottom: 0px;
  }
}
#block-views-science-topic-carousel-block
  > div
  > div.block-content.content
  > div
  > div
  div.field-content
  span {
  top: -0.5em;
}

.booklist-link {
  clear: both;
}

#block-views-related-block h2.block-title,
#block-views-related-videos-block-2 h2.block-title,
#block-views-related-videos-block-1 h2.block-title {
  background-color: #ed1c24;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}
#block-system-main > div > div > div > div.view-header > div > div.view-footer {
  margin-bottom: 15px;
}
#block-system-main
  > div
  > div
  > div
  > div.attachment.attachment-before
  > div
  > div
  > div
  > div.fRight {
  margin-bottom: 15px;
}

h3#\32,
#block-views-related-block
  > div
  > div.block-content.content
  > div
  > div.view-content
  > div
  h3 {
  /*font-size: 1.7em;*/
}
#block-system-main > div > div > div > div.view-header > h2 {
  font-family: $body-font;
  font-weight: 700;
  font-size: 1.7em;
  line-height: 1.2;
  padding-top: 10px;
  padding-bottom: 0;
}
div#content #block-swab-share-print-swab-share-print {
  float: right;
  margin-top: -60px;
}

.booklist-right {
  float: left;
  width: 297px;
  margin-left: -297px;
  margin-top: -15px;
  margin-bottom: 20px;
}
.booklist-left {
  float: left;
  width: calc(100% - 297px);
  margin-right: 297px;
  box-sizing: border-box;
  padding-right: 20px;
}
.booklist-share {
  float: right;
}
ul#share li:last-child {
  margin-right: 0px;
}
.booklist-right .block {
  // margin-left: 40px;
  margin-top: 10px;
  margin-bottom: 0px !important;
}
.booklist-share div#block-swab-share-print-swab-share-print-block {
  margin-bottom: 15px;
}

@media only screen and (min-width: 1025px) {
  .booklist-page #main-content {
    margin-right: 0px;
  }

  .booklist-page #block-system-main .block-inner {
    margin-right: 0px;
    margin-left: 0px;
  }
}

.view-booklist .view-content h3 {
  width: 100%;
}
.view-booklist .view-header ~ .view-content .booklist-section {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.bookbox {
  width: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(34,34,34,.25);
  margin-bottom: 1.8em;
  border-radius: 4px;

  @media only screen and (min-width: 480px) {
    width: 47%;
    margin-right: 3%;
  }

  @media only screen and (min-width: 960px) {
    width: 22%;
    margin-right: 3%;
  }
}

.view-booklist .views-row .book_cover {
  width: 100% !important;
  margin: 0px;
  margin-bottom: -7px;
}
.book_blurb {
  max-width: 100% !important;
}
.bookdescrip,
.bookdescrip p {
  width: 100%;
}
.reveal-button {
  width: 100%;
}
.reveal-button div {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.reveal-button a,
.hide-button a {
  cursor: pointer;
  color: #0071bc;
  font-size: 1.2em;
}
.reveal-button a:hover,
.hide-button a:hover {
  cursor: pointer;
  color: #0071bc;
  font-size: 1.2em;
  text-decoration: none;
}
.hide-button {
  width: 100%;
}
.hide-button div {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.related-reveal-button {
  width: 100%;
}
.related-reveal-button div {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.related-reveal-button a,
.related-hide-button a {
  cursor: pointer;
  color: #0071bc;
  font-size: 1.2em;
}
.related-reveal-button a:hover,
.related-hide-button a:hover {
  cursor: pointer;
  color: #0071bc;
  font-size: 1.2em;
  text-decoration: none;
}
.related-hide-button {
  width: 100%;
}
.related-hide-button div {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.hide-this {
  display: none;
}
.view-footer .view-related > .view-content > .item-list {
  border-top: 1px solid lightgrey;
  margin-top: 20px;
}
.view-footer .view-related > .view-content > .item-list ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}
.view-footer .view-related > .view-content > .item-list {
  ul {
    display: block;
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-gap: 20px;
    column-gap: 20px;
  }
  li {
    // width: 46%;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 10px;
  }
}

.view-footer
  .view-related
  > .view-content
  > .item-list
  li
  img.resource-type-icon {
  display: inline-block;
  width: 1em !important;
  height: auto;
  vertical-align: middle;
  padding-left: 2px;
}

.view-footer .view-related > .view-content > .item-list h3 {
  font-size: 1.7em;
  line-height: 1.2;
}

.view-footer .view-related > .view-content > .item-list h3 p {
  font-size: 0.6em;
  line-height: 1.3;
  color: black;
  margin-top: 10px;
}
.view-footer .view-related > .view-content > .item-list:first-child {
  border-top: none;
}
.view-footer .view-related > .view-content > .item-list:first-child li img {
  display: block;
}
.view-footer .view-related > .view-content > .item-list:first-child {
  ul {
    display: flex;
    -moz-column-count: auto;
    -webkit-column-count: auto;
    column-count: auto;
    -moz-column-gap: unset;
    -webkit-column-gap: unset;
    column-gap: unset;
  }
  li {
    width: 30%;
    margin-right: 3%;
    margin-left: 0;
    margin-bottom: 1.8em;
    list-style-type: none;
    background-color: #ededed;
    padding: 0;
  }
}
.view-footer .view-related > .view-content > .item-list:first-child li {
  @include micro-clearfix;

  .views-field-field-url {
    max-width: 100%;
    display: block;
    color: #0071bc;
    background-color: #ededed;

    a {
      display: block;
      padding: 10px;
      line-height: 1.3;
    }
  }

  .views-field-field-source {
    color: #464646;
    font-size: 80%;
    margin-top: -8px;
    margin-bottom: -10px;
    padding: 0 10px 10px;
    background-color: #ededed;
  }
}

.view-footer
  .view-related
  > .view-content
  > .item-list:first-child
  li
  > .views-field-field-url
  a {
  color: #0071bc;
}

.view-footer .view-related .view-footer {
  border-top: 1px solid lightgrey;
  margin-top: 20px;
}
.view-footer .view-related .view-footer .view-related-literacy-bags {
  width: 29%;
  margin-right: 3%;
  float: left;
}
// .view-footer .view-related .view-footer .view-related-videos {
//     width: 29%;
//     margin-right: 3%;
//     float: left;
// }
.view-footer .view-related .view-footer .view-related-dc-activities {
  width: 29%;
  margin-right: 3%;
  float: left;
}
.view-footer .view-related .view-footer .view-related-literacy-bags img {
  width: 100%;
}
.view-footer
  .view-related
  .view-footer
  .view-related-literacy-bags
  .view-content {
  max-width: 100%;
}
.view-footer > h2 {
  font-family: $body-font;
  font-weight: 700;
  font-size: 1.7em;
  line-height: 1.2;
  margin-bottom: 10px;
  margin-top: 25px;
}
.view-footer .view-related-videos .view-content h3 {
  margin-top: 0px;
}
.view-footer .view-related-literacy-bags h3 {
  margin-bottom: 0.7em;
  margin-top: 0;
}
.view-footer .view-related .view-footer .view-related-dc-activities h3 {
  margin: 1em 0 0.7em 0;
}
.view-footer
  .view-related
  .view-footer
  .view-related-dc-activities
  .view-header
  h3 {
  margin-top: 0px;
}
.view-footer
  .view-related
  .view-footer
  .view-related-dc-activities
  .view-header
  p {
  margin-bottom: 0px;
}
.view-footer
  .view-related
  .view-footer
  .view-related-dc-activities
  .view-footer {
    border-top: none !important;
    margin-top: 0 !important;
}
.view-footer .view-related .view-content > .item-list:nth-child(2) {
  border-top: none;
}

.view-related-blogs .view-footer {
  border-top: none !important;
  margin-top: 0 !important;
}

.view-footer
  .view-related
  .view-content
  > .item-list:first-child
  ul
  li
  > span:not(.views-field-field-url) {
  display: none;
}
.views-field.views-field-field-getting-started-title {
  float: left;
  width: 65%;
}
.views-field.views-field-field-getting-started-sub-title {
  float: left;
  width: 65%;
}
.views-field.views-field-field-getting-started-body {
  float: left;
  width: 65%;
}
.views-field.views-field-field-getting-started-image {
  float: right;
  width: 30%;
}
.view-getting-started {
  margin-top: 20px;
}
.view-booklist > .view-content .booklist-section > h3 {
  width: 80%;
}
.view-booklist > .view-content .booklist-section > a.print-list {
  width: 20%;
  margin: 1em 0 1em 0;
  cursor: pointer;
}
.view-booklist > .view-content .booklist-section > a.print-list img {
  vertical-align: middle;
  margin-right: 5px;
  width: 1em !important;
}
hr {
  border-top: 1px solid #ccc;
  border-bottom: none;
  border-left: none;
  border-right: none;
}
.view-getting-started hr {
  margin-bottom: 20px;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .book_title,
  .book_title * {
    margin-bottom: -10px !important;
  }
  .bookdata,
  .bookdata * {
    margin-bottom: 50px !important;
  }
}
@media only screen and (max-width: 480px) {
  .booklist-right,
  .views-field-field-getting-started-image {
    display: none;
  }
  .booklist-left {
    width: 100%;
  }
  .views-field.views-field-field-getting-started-title {
    float: left;
    width: 100%;
  }
  .views-field.views-field-field-getting-started-sub-title {
    float: left;
    width: 100%;
  }
  .views-field.views-field-field-getting-started-body {
    float: left;
    width: 100%;
  }
  .bookbox {
    width: 100%;
    margin-right: 3%;
  }
  .print-list {
    display: none;
  }
  .view-footer .view-related > .view-content > .item-list ul {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
  .view-footer .view-related > .view-content > .item-list:first-child li {
    width: 47%;
  }
  .booklist-page #bottom-banner-content-wrapper {
    display: none;
  }
  .view-footer .view-related .view-footer .view-related-literacy-bags {
    width: 100%;
    margin-right: 0%;
    float: left;
  }
  .view-footer .view-related .view-footer .view-related-videos {
    width: 100%;
    margin-right: 0%;
    float: left;
  }
  .view-footer .view-related .view-footer .view-related-dc-activities {
    width: 100%;
    margin-right: 0%;
    float: left;
  }
}
.view-booklist h1 {
  margin-bottom: 25px;
}
.view-booklist .booklist-share {
  margin-bottom: 20px;
}
.view-booklist .fRight {
  margin-top: -20px;
}
.view-booklist .view-related-videos img {
  margin-bottom: 10px;
}
.view-booklist .view-related > .view-content .item-list:first-child ul a {
  font-size: 1.1em;
}
#block-views-related-dc-activities-block
  .block-content
  .view-related-dc-activities {
  padding: 1em;
}
.views-field-field-getting-started-image a {
  margin-bottom: 20px;
  display: block;
}

.info-box {
  border: 1px solid $swab-gray;
  background: $swab-beige;
  padding: $horiz-gutter $horiz-gutter * 2;

  b,
  strong,
  h2.inline {
    color: $swab-dark-blue;
    font-weight: 700;
  }

  h2.inline {
    font-family: $body-font;
    font-size: 1rem;
  }

  .inline {
    display: inline;
  }
}

.search-results .search-info {
  display: none;
}